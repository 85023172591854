@charset "UTF-8";
@import "functions/hls";

$ci-primary: #004B87;
$ci-secondary: #F06400;
$ci-tertiary: #edf7ff;
$ci-link-color: $ci-secondary;
$ci-table-background-color: #dcdcdc;
$ci-grey-light: #e8e8e8;

// include partials (please add partials only in the include file)
@import "partials/include";
